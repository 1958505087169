require("turbolinks").start()
require("channels")
require("trix")
require("@rails/actiontext")
require("@rails/activestorage").start()

import "controllers"
import "bootstrap"
import "popper.js"
import "bootstrap-slider/dist/bootstrap-slider.min.js"
import "bootstrap-slider/dist/css/bootstrap-slider.min.css"
import "dropzone/dist/dropzone.css"
import Rails from "@rails/ujs"
Rails.start()


const appendAlert = (body, theType) => {
    let _theType = "alert-success"
    if (theType) {
        _theType = theType
    }

    $(".alert-container").append($(`
        <div class="alert alert-dismissible fade show ${_theType}" role="alert">
        ${body}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" style="padding: 0 1rem 0 0; display: flex; align-items: center; height: 100%;">
            <span aria-hidden="true" style="font-size: 0.75em;">&times;</span>
        </button>
        </div>
    `).delay(2000).fadeOut())
}

window.appendAlert = appendAlert

addEventListener("direct-upload:initialize", event => {
    console.log('Files Initialized');
    const { target, detail } = event
    const { id, file } = detail
    target.insertAdjacentHTML("beforebegin", `
      <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
        <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
        <span class="direct-upload__filename"></span>
      </div>
    `)
    target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
})

addEventListener("direct-upload:start", event => {
const { id } = event.detail
const element = document.getElementById(`direct-upload-${id}`)
element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
    const { id, progress } = event.detail
    console.log('FILE PROGRESS', progress)
const progressElement = document.getElementById(`direct-upload-progress-${id}`)
progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
event.preventDefault()
const { id, error } = event.detail
const element = document.getElementById(`direct-upload-${id}`)
element.classList.add("direct-upload--error")
element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
const { id } = event.detail
const element = document.getElementById(`direct-upload-${id}`)
element.classList.add("direct-upload--complete")
})

$(document).on('turbolinks:load', () => {
    $('.remote-form').on('ajax:success', (event) => appendAlert($(event.target).data('body'), 'alert-success'))
    $('.remote-form').on('ajax:error', () => appendAlert('Oh no, there was an error.', 'alert-danger'))

    $('#submission_image').on('direct-upload:start', (id, file) => console.log('START', id, file))
    $('#submission_image').on('direct-upload:start', (id, file, progress) => console.log('PROGRESS', progress))

    $('#input-value').slider({
        formatter: (value) => `${value}%`
    })

    // validation
    $('.required').each((j, initialObj) => {
        const value = initialObj.value
        if ($.trim(value) !== '') {
            console.log('looks good!')
            $(initialObj).removeClass('is-invalid')
        }
        else {
            $(initialObj).addClass('is-invalid')
        }

        let values = []
        $('.new-submission-form .required').each((i, obj) => {
            const $input = $(obj)
            if ($input.prop('tagName') === 'TRIX-EDITOR') {
                values.push($input.parent().find('input[type="hidden"]').val() !== '')
            }
            else {
                values.push($input.val() !== '')
            }
        })
        console.log('VALUES', values)

        if (values.indexOf(false) > -1) {
            $('.new-submission-form').find('button[type="submit"]').attr('disabled', 'disabled')
        }
        else if (values.indexOf(false) === -1) {
            $('.new-submission-form').find('button[type="submit"]').removeAttr('disabled')
            console.log('enabled!!!')
        }
    })

    $('.required').on('blur', (event) => {
        const value = event.target.value
        if ($.trim(value) !== '') {
            console.log('looks good!')
            $(event.target).removeClass('is-invalid')
        }
        else {
            $(event.target).addClass('is-invalid')
        }

        let values = []
        $('.new-submission-form .required').each((i, obj) => {
            const $input = $(obj)
            if ($input.prop('tagName') === 'TRIX-EDITOR') {
                values.push($input.parent().find('input[type="hidden"]').val() !== '')
            }
            else {
                values.push($input.val() !== '')
            }
        })
        console.log('VALUES', values)

        if (values.indexOf(false) > -1) {
            $('.new-submission-form').find('button[type="submit"]').attr('disabled', 'disabled')
        }
        else if (values.indexOf(false) === -1) {
            $('.new-submission-form').find('button[type="submit"]').removeAttr('disabled')
            console.log('enabled!!!')
        }
    })

    const $submisssionForm = $('.new-submission-form')
    $submisssionForm.on('ajax:before', () => {
        $submisssionForm.append('<input name="submit" value="true" type="hidden" />')
    })

    $(document).on('click', '#checkout-button', async () => {
        $('#checkout-button').html("<div class='spinner-border spinner-border-sm' role='status'><span class='sr-only'>Loading...</span></div>")
        const stripe = Stripe(process.env.STRIPE_PK)
        console.log('the pk', process.env.STRIPE_PK)
        const $stripeBtn = $('#checkout-button')
        console.log('ID', $stripeBtn.data("session-id"))
        const { error } = await stripe.redirectToCheckout({
            sessionId: $stripeBtn.data("session-id")
        })
        if (error) {
            console.log(error)
        }
    })

    $('.user-select').on('change', (event) => {
        const $target = $(event.target)
        console.log(event.target.value, event.target.id)
        $.ajax({
            url: $target.data('url'),
            method: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify({
                user: {
                    role_id: event.target.value
                }
            }),
            success: (data, status) => {
                console.log(data, status)
                appendAlert('User role updated!', 'alert-success')
            },
            error: (xhr, status, error) => {
                console.log(error)
                appendAlert('Could not update user.', 'alert-danger')
            }
        })
    })

    $(document).on('click', '.remove-order', (e) => {
        const $order = $(e.target).parents('.order')
        $order.remove()
    })
})
